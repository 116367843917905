// src/pages/UserInfoPage.tsx
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUser, updateUser, User } from "../api/UserApi";

const UserInfoPage: React.FC = () => {
    const { userId } = useParams<{ userId: string }>();
    const [user, setUser] = useState<User | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        const fetchUser = async () => {
            if (userId) {
                const fetchedUser = await getUser(userId);
                setUser(fetchedUser);
            }
        };

        fetchUser();
    }, [userId]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        if (user) {
            await updateUser(user);
            setIsEditing(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUser((prevUser) => (prevUser ? { ...prevUser, [name]: value } : null));
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>User Info</h2>
            <div>
                <label>
                    Name:
                    {isEditing ? (
                        <input
                            type="text"
                            name="name"
                            value={user.name}
                            onChange={handleChange}
                        />
                    ) : (
                        <span>{user.name}</span>
                    )}
                </label>
            </div>
            <div>
                <label>
                    Email:
                    {isEditing ? (
                        <input
                            type="email"
                            name="email"
                            value={user.email}
                            onChange={handleChange}
                        />
                    ) : (
                        <span>{user.email}</span>
                    )}
                </label>
            </div>
            <div>
                <label>
                    Age:
                    {isEditing ? (
                        <input
                            type="number"
                            name="age"
                            value={user.age}
                            onChange={handleChange}
                        />
                    ) : (
                        <span>{user.age}</span>
                    )}
                </label>
            </div>
            {isEditing ? (
                <button onClick={handleSaveClick}>Save</button>
            ) : (
                <button onClick={handleEditClick}>Edit</button>
            )}
        </div>
    );
};

export default UserInfoPage;
