import React, { useEffect, useState, useRef } from "react";
import {
  ChatMessageError,
  ProChat,
  ProChatInstance,
} from "@ant-design/pro-chat";
import { useTheme } from "antd-style";
import { BASE_URL } from "../../utils/BaseInfo";
import { useAuth } from "../../utils/AuthContext";
import { Button, Card, Result } from "antd";

interface ChatProComponentProps {
  sessionId: string;
}

const ChatProComponent: React.FC<ChatProComponentProps> = ({ sessionId }) => {
  const theme = useTheme();
  const apiUrl = BASE_URL;
  const { getToken } = useAuth();
  const token = getToken();
  const proChatRef = useRef<ProChatInstance>();

  const [loading, setLoading] = useState(true);
  const [initialChats, setInitialChats] = useState([]);
  const [error, setError] = useState<ChatMessageError | null>(null);

  useEffect(() => {
    const fetchSessionData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${apiUrl}/api/OpenAIChat/session/${sessionId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          console.log("initialChats: ", data.initialChats); // 确保数据格式正确
          setInitialChats(data.initialChats);
        } else {
          console.error("Failed to fetch session data");
          setError({ message: "Failed to fetch session data", type: "error" });
        }
      } catch (error) {
        console.error("Failed to fetch session data", error);
        setError({ message: "Failed to fetch session data", type: "error" });
      }
      setLoading(false);
    };
    fetchSessionData();
  }, [apiUrl, token, sessionId]);

  const handleRequest = async (messages: string | any[]) => {
    try {
      const response = await fetch(`${apiUrl}/api/OpenAIChat/stream-sse`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userInput: messages[messages.length - 1].content,
          sessionId,
          model: "GPT_3_5_Turbo",
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (!response.body) {
        throw new Error("Stream not supported");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      const stream = new ReadableStream({
        async start(controller) {
          function push() {
            reader
              .read()
              .then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                try {
                  const chunk = decoder.decode(value, { stream: true });
                  const lines = chunk.split("\n");

                  for (let line of lines) {
                    if (line.startsWith("data: ")) {
                      const message = line.replace("data: ", "").trim();
                      if (message) {
                        const parsed = JSON.parse(message);
                        console.log("parsed: ", parsed);
                        if (parsed.Response) {
                          controller.enqueue(
                            new TextEncoder().encode(parsed.Response)
                          );
                        }
                      }
                    }
                  }
                  push();
                } catch (error) {
                  console.error("Error decoding stream", error);
                  controller.error(error);
                }
              })
              .catch((error) => {
                console.error("Error reading stream", error);
                controller.error(error);
              });
          }
          push();
        },
      });

      return new Response(stream, {
        headers: { "Content-Type": "text/plain" },
      });
    } catch (error) {
      console.error("Failed to fetch stream", error);
      setError({
        message: error instanceof Error ? error.message : "Unknown error",
        type: "error",
      });
      throw error;
    }
  };

  const renderErrorMessage = (errorResponse: ChatMessageError) => {
    return (
      <Card>
        <Result
          status="error"
          title="Something Error"
          subTitle={errorResponse.message}
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => window.location.reload()}
            >
              Try Again
            </Button>,
          ]}
        />
      </Card>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return renderErrorMessage(error);
  }

  return (
    <div
      style={{ background: theme.colorBgLayout, height: "80vh", width: "80vw" }}
    >
      <ProChat
        chatRef={proChatRef}
        helloMessage="Welcome to use"
        style={{ height: "80vh", width: "80vw" }}
        request={handleRequest}
        initialChats={initialChats}
        renderErrorMessages={renderErrorMessage}
      />
    </div>
  );
};

export default ChatProComponent;
