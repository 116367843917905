// src/api/UserApi.ts
export interface User {
    id: string;
    name: string;
    email: string;
    age?: number;
}

export const getUser = async (id: string): Promise<User> => {
    // 这个函数将在 .NET 中实现
    return new Promise((resolve) => {
        resolve({
            id,
            name: "Loading...",
            email: "Loading...",
            age: 0,
        });
    });
};

export const updateUser = async (user: User): Promise<void> => {
    // 这个函数将在 .NET 中实现
    return new Promise((resolve) => {
        resolve();
    });
};
