import { Layout, Menu } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { PieChartOutlined, DesktopOutlined } from "@ant-design/icons";
import "antd/dist/antd";
import ChatProComponent from "../components/Chat/ChatPro";
import { BASE_URL } from "../utils/BaseInfo";
import { useAuth } from "../utils/AuthContext";

const apiUrl = BASE_URL;

const ChatPage = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const navigate = useNavigate();
  const { sessionId } = useParams<{ sessionId: string }>();
  const [collapsed, setCollapsed] = useState(false);

  interface Session {
    id: string;
    title: string;
  }

  const { getToken } = useAuth();
  const token = getToken();
  const [sessions, setSessions] = useState<Session[]>([]);
  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(
    null
  );

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/OpenAIChat/session`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setSessions(data);
        if (sessionId) {
          setSelectedSessionId(sessionId);
        } else if (data.length > 0) {
          navigate(`/chat/${data[0].id}`);
        }
      } catch (error) {
        console.error("Failed to fetch sessions", error);
      }
    };

    fetchSessions();
  }, [token, navigate, sessionId]);

  const menuItems = sessions.map((session) => ({
    key: session.id,
    icon: <PieChartOutlined />,
    label: session.title,
    onClick: () => navigate(`/chat/${session.id}`),
  }));

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <div className="logo" />
        <Menu
          theme="dark"
          selectedKeys={[selectedSessionId || ""]}
          mode="inline"
          items={menuItems}
        />
      </Sider>
      <Layout>
        <Header style={{ color: "#fff", textAlign: "center" }}>Header</Header>
        <Content
          style={{ margin: "24px 16px", padding: 24, background: "#fff" }}
        >
          {selectedSessionId && (
            <ChatProComponent sessionId={selectedSessionId} />
          )}
        </Content>
        <Footer style={{ textAlign: "center" }}>dob!~</Footer>
      </Layout>
    </Layout>
  );
};

export default ChatPage;
