import React from "react";
import { Form, Input, Button, Card, message } from "antd";

interface LoginEmailProps {
  onLogin: (values: { username: string; password: string }) => void;
  loading: boolean;
}

const LoginEmail: React.FC<LoginEmailProps> = ({ onLogin, loading }) => {
  const [form] = Form.useForm();

  const onFinish = (values: { username: string; password: string }) => {
    onLogin(values);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "10vh",
        paddingTop: "1vh",
      }}
    >
      <Card style={{ width: 350, textAlign: "center", height: "auto", minHeight: "300px" }}>
        <Form
          form={form}
          name="login_form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginEmail;
