import React from "react";
import { List, Typography, Button } from 'antd';

const { Title } = Typography;

const CartPage = () => {
  const cartItems = [
    {
      title: 'Product 1',
      description: 'This is product 1',
    },
    {
      title: 'Product 2',
      description: 'This is product 2',
    },
    // Add more items as needed
  ];

  return (
    <>
      <Title level={2}>Shopping Cart</Title>
      <List
        itemLayout="horizontal"
        dataSource={cartItems}
        renderItem={item => (
          <List.Item actions={[<Button type="link">Remove</Button>]}>
            <List.Item.Meta
              title={item.title}
              description={item.description}
            />
          </List.Item>
        )}
      />
      <Button type="primary" style={{ marginTop: '16px' }}>Proceed to Checkout</Button>
    </>
  );
};

export default CartPage;
