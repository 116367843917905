const BASE_URL = "https://your-api-domain.com/api";

async function login(email, password) {
  const response = await fetch(`${BASE_URL}/users/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  const data = await response.json();
  return data; // 返回登录成功后的用户信息和令牌等
}

async function register(userDetails) {
  const response = await fetch(`${BASE_URL}/users/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userDetails),
  });
  const data = await response.json();
  return data; // 返回注册成功后的信息
}

async function getUserProfile(token) {
  const response = await fetch(`${BASE_URL}/users/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data; // 返回用户的个人信息
}

async function sendVerificationCode(phoneNumber) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/send-verification-code`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ phoneNumber }),
    }
  );
  const data = await response.json();
  return data;
}

async function verifyCode(phoneNumber, code) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/verify-code`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ phoneNumber, code }),
    }
  );
  const data = await response.json();
  return data;
}
export const userService = {
  login,
  register,
  getUserProfile,
  verifyCode,
  sendVerificationCode,
};
