import React, { useState } from "react";
import { Form, Input, Button, Card, message, Space } from "antd";
import { userService } from "../api/userService"; // 确保路径正确

interface LoginPhoneProps {
  onLogin: (values: { phone: string; code: string }) => void;
  loading: boolean;
}

const LoginPhone: React.FC<LoginPhoneProps> = ({ onLogin, loading }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [code, setCode] = useState<string>("");

  const handleSendCode = async () => {
    try {
      const result = await userService.sendVerificationCode(phoneNumber);
      console.log(result.message); // 显示发送验证码的结果
      message.success("Verification code sent!");
    } catch (error: unknown) {
      console.error("Sending verification code error", error);
      message.error((error as Error).message || "Failed to send verification code");
    }
  };

  const handleSubmit = () => {
    onLogin({ phone: phoneNumber, code });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "10vh",
        paddingTop: "1vh",
      }}
    >
      <Card style={{ width: 350, textAlign: "center", height: "auto", minHeight: "300px" }}>
        <Form>
          <Form.Item>
            <Input
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
          <Button onClick={handleSendCode} loading={loading} block>
            Send Verification Code
          </Button>
          <Space style={{ display: "block", height: "16px" }} />
          <Form.Item>
            <Input
              placeholder="Verification Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={loading}
              block
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginPhone;
