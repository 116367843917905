import React, { useState } from "react";
import { Button, Card, Space, message } from "antd";
import { useNavigate } from "react-router-dom";
import LoginEmail from "../components/LoginEmail";
import LoginPhone from "../components/LoginPhone";
import { useAuth } from "../utils/AuthContext"; // 导入 useAuth 钩子
import { BASE_URL } from "../utils/BaseInfo";

const LoginCombined = () => {
  const [loginMethod, setLoginMethod] = useState("email");
  const [loading, setLoading] = useState(false);
  const baseUrl = BASE_URL;
  const navigate = useNavigate();
  const { login } = useAuth(); // 使用 useAuth 钩子

  // console.log("baseUrl: ", baseUrl);
  const handleLogin = async (loginData: any) => {
    setLoading(true);
    try {
      // const response = await fetch(`${baseUrl}/api/account/login/${loginMethod}`, {
      const response = await fetch(`${baseUrl}/api/account/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });
      const result = await response.json();

      if (response.ok) {
        login(result.token); // 调用 login 函数保存令牌
        message.success("Login successful!!!!");
        navigate("/chat"); // 重定向或其他操作
      } else {
        message.error(result.message || "Login failed!");
      }
    } catch (error) {
      console.error("Login error:", error);
      message.error("An error occurred during login.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card style={{ width: 350, textAlign: "center" }}>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <div>
            <Button
              type={loginMethod === "email" ? "primary" : "default"}
              onClick={() => setLoginMethod("email")}
            >
              Email Login
            </Button>
            <Button
              style={{ marginLeft: 16 }}
              type={loginMethod === "phone" ? "primary" : "default"}
              onClick={() => setLoginMethod("phone")}
            >
              Phone Login
            </Button>
          </div>
          {loginMethod === "email" ? (
            <LoginEmail onLogin={handleLogin} loading={loading} />
          ) : (
            <LoginPhone onLogin={handleLogin} loading={loading} />
          )}
        </Space>
      </Card>
    </div>
  );
};

export default LoginCombined;
