import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import ChatPage from "./pages/ChatPage";
import LoginCombined from "./pages/LoginPage";
import "./App.css";
import Header from "./components/Header";
import { Buffer } from "buffer";
import "stream-browserify";
import { AuthProvider, ProtectedRoute, useAuth } from "./utils/AuthContext";
import ProductPage from "./pages/ProductPage";
import CartPage from "./pages/CartPage";
import AppHeader from "./components/Header";
import UserInfoPage from "./pages/UserInfoPage";

function AppContent() {
  const location = useLocation();
  const isChatPage = location.pathname.startsWith("/chat");

  return (
    <div className="App">
      {/* {!isChatPage && <AppHeader />} */}
      <Routes>
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/chat/:sessionId" element={<ChatPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/products" element={<ProductPage />} />
        {/* <Route path="/login" element={<ProtectedRoute />} />
         */}
        <Route path="/login" element={<ProtectedRoute element={<LoginCombined />} redirectTo="/chat" />} />
        <Route path="/user/:userId" element={<ProtectedRoute element={<UserInfoPage />} redirectTo="/login" />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
